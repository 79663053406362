import { forwardRef, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Toolbar,
  Container,
  Typography,
} from '@material-ui/core';
import { ProfileDropdown } from 'components';
import NotificationIndicator from '../NotificationIndicator/NotificationIndicator.component';
import mainLogo from 'assets/images/logo-small.png';
import { useStyles } from './Header.styles';

const Header = (_, ref) => {
  const { isAuthenticated } = useSelector(state => state.authentication);

  const isFirstSignIn = useSelector(state => state.user.isFirstSignIn);

  const classes = useStyles();

  const { replace } = useHistory();

  const onNavigateHandler = () => {
    if (isFirstSignIn) {
      return;
    }
    replace(isAuthenticated ? '/dashboard' : '/');
  };

  const onTutorialsNavigateHandler = () => {
    if (isFirstSignIn) {
      return;
    }
    replace('/tutorials');
  };

  return (
    <AppBar position="static" ref={ref}>
      <Container maxWidth="xl">
        <Toolbar>
          <CardMedia
            component="img"
            image={mainLogo}
            className={classes.logo}
            onClick={onNavigateHandler}
          />
          <Box className={classes.navbar}>
            {isAuthenticated && (
              <Fragment>
                <Box className={classes.gap}>
                  <Typography
                    variant="body2"
                    className={classes.link}
                    onClick={onTutorialsNavigateHandler}
                  >
                    Tutoriales
                  </Typography>
                </Box>
                <Box className={classes.gap}>
                  <NotificationIndicator />
                  <ProfileDropdown />
                </Box>
              </Fragment>
            )}
            {!isAuthenticated && (
              <Box className={classes.gap}>
                <Button
                  to="/signIn"
                  variant="text"
                  color="inherit"
                  component={Link}
                >
                  Iniciar sesión
                </Button>
                <Button
                  to="/signUp"
                  variant="text"
                  color="inherit"
                  component={Link}
                >
                  Registrarme
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default forwardRef(Header);
