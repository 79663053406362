import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import {
  Box,
  CardMedia,
  Drawer,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../Button/Button.component';

import amex from 'assets/icons/card-amex.svg';
import visa from 'assets/icons/card-visa.svg';
import master from 'assets/icons/card-master.svg';

import { useDrawer } from 'hooks/drawers.hook';
import {
  useBuyBundle,
  useSuscription,
  useValidateDiscountCode,
} from 'hooks/payments.hook';

import { DRAWERS } from 'utils/constants';
import { currencyFormatter } from 'utils/helpers';

import { useStyles } from './CheckoutDrawer.styles';
import { useSnackbar } from 'notistack';

import conekta from 'assets/images/conekta.png';
import { useHistory } from 'react-router-dom';
import { setModalStatus } from '../../../redux/slices/modal.slice';
import { useQueryClient } from 'react-query';

const CheckoutDrawer = () => {
  const [isDefault, setDefault] = useState(true);
  const [cardList, setCardList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');
  const [today] = useState(new Date());
  const [discountCode, setDiscountCode] = useState('');
  const [promo, setPromo] = useState({});
  const [hasDiscountError, setHasDiscountError] = useState({
    hasError: false,
    message: '',
  });

  const { cards, order } = useSelector(state => state.payments);
  const { isFirstSignIn } = useSelector(state => state.user);

  const { mutateAsync: buyBundle, isLoading, isSuccess } = useBuyBundle();

  const queryClient = useQueryClient();

  const { mutateAsync: subscribe, isLoading: isLoadingSuscription } =
    useSuscription();

  const { refetch: validateDiscountCode } =
    useValidateDiscountCode(discountCode);

  const { visibility, handleClose } = useDrawer(DRAWERS.checkout);
  const { handleOpen } = useDrawer(DRAWERS.addCard);

  const classes = useStyles();

  const hasPaymentMethod = !isEmpty(cards);

  const { replace } = useHistory();

  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = key => (
    <Fragment>
      <Button color="secondary" onClick={() => closeSnackbar(key)}>
        Confirmar
      </Button>
    </Fragment>
  );

  const onNewCardClickHandler = event => {
    event.preventDefault();
    handleOpen();
  };

  const onCardChangeHandler = event => {
    event.preventDefault();
    setSelectedCardId(event.target.value);
  };

  const onPaymentChangeHandler = event => {
    event.preventDefault();
    setDefault(false);
  };

  const onConfirmClickHandler = event => {
    event.preventDefault();
    if (order.type === 'passes') {
      buyBundle({
        cardId: selectedCardId,
        storeProductId: order.id,
        instalments: order.interestFree,
        discountCode: promo?.code ?? null,
      })
        .then(() => {
          if (isFirstSignIn) {
            dispatch(
              setModalStatus({
                name: 'PENDING_AGENDA_SETTINGS',
                isActive: true,
              }),
            );
          }
        })
        .catch(({ response }) => {
          if (response.data.isPublic) {
            enqueueSnackbar(response.data.message, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(
              'No se pudo completar la transacción, intenta más tarde',
              {
                variant: 'error',
              },
            );
          }
        });
    }
    if (order.type === 'subscription') {
      subscribe({
        cardId: selectedCardId,
        storeProductId: order.id,
      }).then(() => {
        queryClient.invalidateQueries('GET_DETAILS');
        if (isFirstSignIn) {
          setTimeout(() => {
            replace('/settings/profile/market/passes');
          }, 1000);
        }
      });
    }
  };

  const onValidateDiscountCode = () => {
    validateDiscountCode().then(response => {
      if (response.isSuccess) {
        setPromo(response.data);
        setHasDiscountError({
          hasError: false,
          message: '',
        });
      }
      if (response.isError) {
        if (response?.error?.response?.data?.isPublic) {
          setHasDiscountError({
            hasError: true,
            message: response?.error?.response?.data?.message,
          });
          setPromo({});
        }
      }
    });
  };

  useEffect(() => {
    if (typeof cards !== 'undefined') {
      const defaultCard = cards.find(card => card.default);
      const mapped = cards.map(card => ({
        ...card,
        logo: {
          mastercard: master,
          visa: visa,
          american_express: amex,
        }[card.brand],
      }));
      setCardList(mapped);
      setSelectedCardId(defaultCard?.id ?? '');
    }
  }, [cards]);

  if (isSuccess) {
    enqueueSnackbar(
      'Se realizó la compra con éxito, tus pases se han agregado a tu cuenta',
      {
        variant: 'success',
        persist: true,
        action,
      },
    );
  }

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={() => {
        handleClose();
        setPromo({});
        setDiscountCode('');
        setHasDiscountError({
          hasError: false,
          message: '',
        });
      }}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Realiza tu compra
        </Typography>
      </Box>
      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.subtitle}>
          {order.type === 'subscription'
            ? 'Detalles de tu suscripción'
            : 'Detalles de la compra'}
        </Typography>
        {order.type === 'passes' && (
          <Typography variant="body2">
            Al adquirir pases, podrás asignarlos a tus pacientes cuando así lo
            desees. Los pases que adquieras no tienen fecha de caducidad.
          </Typography>
        )}
      </Box>
      <Box pt={4} component="section">
        <Typography variant="h6" className={classes.orderTitle}>
          {order.name}
        </Typography>
        <Typography variant="body2" className={classes.center}>
          Total:&nbsp;
          <NumberFormat
            value={order.total * 100}
            displayType="text"
            format={currencyFormatter}
            className={`${!isEmpty(promo) && classes.discount}`}
          />{' '}
          &nbsp;
          {!isEmpty(promo) && (
            <NumberFormat
              value={
                order.total * 100 - order.total * 100 * (promo.discount / 100)
              }
              displayType="text"
              format={currencyFormatter}
            />
          )}
          {order.type === 'subscription' && ' / mes'}
        </Typography>
        {!isEmpty(promo) && (
          <Typography style={{ paddingTop: 8 }} variant="h6" align="center">
            Se aplicó un {promo.discount}% de descuento
          </Typography>
        )}
        {hasDiscountError.hasError && (
          <Typography variant="h6" style={{ color: 'red', paddingTop: 8 }}>
            {hasDiscountError?.message ??
              'El código que ingresaste no existe, intenta con otro.'}
          </Typography>
        )}
        {order.type === 'subscription' && (
          <Box pt={2}>
            <Typography variant="caption" className={classes.caption}>
              Suscripción
            </Typography>
            <Typography variant="caption" className={classes.caption}>
              Renovación automática cada mes. Los cobros se realizarán los
              días&nbsp;
              {order.subscription?.paymentDay ??
                format(today, 'do', { locale: es })}{' '}
              de cada mes.
            </Typography>
            <Typography variant="caption" className={classes.caption}>
              Próximo pago&nbsp;
              <NumberFormat
                value={order.total * 100}
                displayType="text"
                format={currencyFormatter}
              />
              &nbsp;el día{' '}
              {order.subscription?.nextPaymentDate
                ? format(
                    new Date(order.subscription?.nextPaymentDate),
                    'dd/MM/yyyy',
                  )
                : format(
                    new Date().setMonth(today.getMonth() + 1),
                    'dd/MM/yyyy',
                  )}
            </Typography>
          </Box>
        )}
      </Box>
      {order.type === 'passes' && order.interestFree > 0 && (
        <Box pt={4} component="section" textAlign="center">
          <Typography variant="h6">
            {order.interestFree} meses de&nbsp;
            <NumberFormat
              value={(order.total / order.interestFree) * 100}
              displayType="text"
              format={currencyFormatter}
            />{' '}
            ={' '}
            <NumberFormat
              value={order.total * 100}
              displayType="text"
              format={currencyFormatter}
            />
            {/*$*/}
            {/*{Number(order.total / order.interestFree).toFixed(2)} = $*/}
            {/*{Number(order.total).toFixed(2)}*/}
          </Typography>
        </Box>
      )}
      {order.paymentPlan === 'coinBundle' && order.passesAmount !== 3 && (
        <Box pt={4} component="section">
          <Typography variant="h5" className={classes.subtitle}>
            Código promocional
          </Typography>
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            <TextField
              label="Código promocional"
              variant="outlined"
              onChange={e => setDiscountCode(e.target.value)}
              value={discountCode}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={onValidateDiscountCode}
            >
              Aplicar
            </Button>
          </Box>
        </Box>
      )}
      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.subtitle}>
          Forma de pago
        </Typography>
        {hasPaymentMethod &&
          (isDefault ? (
            <>
              <RadioGroup
                name="payments"
                value={selectedCardId}
                onChange={onCardChangeHandler}
              >
                {cardList
                  .filter(card => card.default)
                  .map(card => (
                    <Box display="flex" alignItems="center" key={card.id}>
                      <Radio value={card.id} color="primary" />
                      <CardMedia
                        component="img"
                        image={card.logo}
                        className={classes.cardLogo}
                      />
                      <Typography
                        variant="body2"
                        className={classes.cardNumber}
                      >
                        &#8226;&#8226;&#8226;&#8226; {card.last4}
                      </Typography>
                    </Box>
                  ))}
              </RadioGroup>
              <Box pt={2}>
                <Typography
                  variant="body2"
                  className={classes.asLink}
                  onClick={onPaymentChangeHandler}
                >
                  Usar otra forma de pago
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <RadioGroup
                name="payments"
                value={selectedCardId}
                onChange={onCardChangeHandler}
              >
                {cardList.map(card => (
                  <Box display="flex" alignItems="center" key={card.id}>
                    <Radio value={card.id} color="primary" />
                    <CardMedia
                      component="img"
                      image={card.logo}
                      className={classes.cardLogo}
                    />
                    <Typography variant="body2" className={classes.cardNumber}>
                      &#8226;&#8226;&#8226;&#8226; {card.last4}
                    </Typography>
                  </Box>
                ))}
              </RadioGroup>
              <Box pt={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  endIcon={<AddIcon />}
                  onClick={onNewCardClickHandler}
                >
                  Agregar forma de pago
                </Button>
              </Box>
            </>
          ))}
        {isFirstSignIn && (
          <Box pt={2} textAlign="center">
            <Typography variant="body2">
              Al confirmar tu orden habrás finalizado el registro
            </Typography>
          </Box>
        )}
        {!hasPaymentMethod && (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            endIcon={<AddIcon />}
            onClick={onNewCardClickHandler}
          >
            Agregar forma de pago
          </Button>
        )}
        <Box pt={2}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!hasPaymentMethod || isLoading || isLoadingSuscription}
            onClick={onConfirmClickHandler}
            loading={isLoading || isLoadingSuscription}
          >
            Confirma tu orden
          </Button>
        </Box>
        <Box pt={2}>
          <Typography variant="body2">
            Datos protegidos encriptados y pagos seguros con Conekta&reg;
          </Typography>
          <CardMedia
            component="img"
            image={conekta}
            className={classes.conekta}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default CheckoutDrawer;
