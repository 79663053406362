import { useMutation, useQuery, useQueryClient } from 'react-query';
import { batch, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  getPlanUsers,
  requestDetails,
  requestSuscriptions,
  getPasses,
  getSpecialities,
  getServices,
  patchProfile,
  patchPassword,
  getLinkRequests,
  acceptLinkRequest,
  cancelLinkRequest,
  rejectLinkRequest,
  getPaymentsHistory,
  getInbodyPassesHistory,
  deletePatientBadges,
  getCalendarEvents,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  getNutritionistTypesList,
  getNutritionistDietsList,
  getNutritionistOffices,
  postCreateNutritionistOffice,
  patchUpdateNutritionistOffice,
  deleteDeleteNutritionistOffice,
  patchUpdateNutritionistOfficeSettings,
  getNutritionistOfficeSchedule,
  postCreateNutritionistOfficeSchedule,
  deleteDeleteNutritionistOfficeSchedule,
  getNutritionistOfficeDaysOff,
  postCreateNutritionistOfficeDayOff,
  deleteDelteNutritionistOfficeDayOff,
  getDeleteOffieAffectedPatients,
  acceptCalendarEvent,
  rejectCalendarEvent,
  proposeNewDateForAppointment,
  getAppointmentCounters,
  getPendingAppointmentsUserList,
  getOfficeHoursAffectedPatients,
  getNewNutritionistNotifications,
  getNotifications,
  markAsSeenNotification,
  patchUpdateNutritionistOfficeSchedule,
  patchEditNutritionistOfficeDayOff,
} from 'network/services/nutritionist.service';

import {
  setAvailablePasses,
  setNutritionistDiets,
  setNutritionistOfficeDaysOff,
  setNutritionistOffices,
  setNutritionistOfficesSchedule,
  setNutritionistServices,
  setNutritionistSpecialties,
  setNutritionistSubscription,
  setNutritionistTypes,
  setUserIsProfileComplete,
  setUserName,
  setUserProfile,
} from 'redux/slices/user.slice';
import { setPhotoBase64, setPhotoPath } from 'redux/slices/cloud.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setInvitations } from 'redux/slices/invitations/invitations.slice';
import { setCalendarEvents } from '../redux/slices/calendarEvents/calendarEvents.slice';
import { useHistory } from 'react-router-dom';
import {
  setInbodyPassesHistory,
  setPaymentsHistory,
} from '../redux/slices/history/history.slice';
import {
  setEditableTarget,
  setIsCreateOfficeFormOpen,
} from '../redux/slices/forms.slice';
import { setList } from '../redux/slices/serviceList.slice';
import { setAffectedPatients } from '../redux/slices/affectedPatients/affectedPatients.slice';
import {
  setPendingAppointmentAfterCreatePatient,
  setUnseenAppointments,
} from '../redux/slices/virtualAgenda/virtualAgenda.slice';
import {
  setWnNotificationCounter,
  setWnNotifications,
} from '../redux/slices/notifications/notifications.slice';
import { NOTIFICATION_TYPES } from '../utils/constants';
import { setWnUserEditableOfficeHours } from '../redux/slices/user/user.slice';

export const usePasses = () => {
  const dispatch = useDispatch();

  return useQuery('GET_PASSES', getPasses, {
    onSuccess: ({ data }) => {
      dispatch(setAvailablePasses(data.activePasses));
    },
  });
};

export const useNutritionist = () => {
  const dispatch = useDispatch();

  return useQuery('GET_DETAILS', requestDetails, {
    onSuccess: data => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        dispatch(setPhotoBase64(dataURL));
      };
      img.src = data.pictureUrl;
      batch(() => {
        dispatch(setPhotoPath(data.pictureUrl));
        dispatch(setUserName(data.name));
        dispatch(setUserProfile(data));
        dispatch(setUserIsProfileComplete(data.isProfileCompleted));
      });
    },
  });
};

export const usePlanUsers = () => {
  const dispatch = useDispatch();

  return useQuery('GET_PLAN_USERS', getPlanUsers, {
    onSuccess: data => {
      const { activatedUsers, subscription, usersInPlan } = data;
      dispatch(
        setNutritionistSubscription({
          activatedUsers,
          subscription,
          usersInPlan,
        }),
      );
    },
  });
};

export const useSuscriptions = () =>
  useQuery('GET_SUSCRIPTIONS', requestSuscriptions);

export const useGetSpecialities = () => {
  const dispatch = useDispatch();

  return useQuery('GET_SPECIALITIES', getSpecialities, {
    onSuccess: data => {
      dispatch(setNutritionistSpecialties(data));
    },
  });
};

export const useGetServices = () => {
  const dispatch = useDispatch();

  return useQuery('GET_SERVICES', getServices, {
    onSuccess: data => {
      batch(() => {
        dispatch(setNutritionistServices(data));
        dispatch(setList(data));
      });
    },
  });
};

export const usePatchProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const queryClient = useQueryClient();

  return useMutation(patchProfile, {
    onSuccess: () => {
      enqueueSnackbar('Tu información ha sido actualizada con éxito', {
        variant: 'success',
      });
      queryClient.invalidateQueries('GET_DETAILS');
      setTimeout(() => {
        push('/settings/profile/services');
      }, 1000);
    },
    onError: () => {
      enqueueSnackbar(
        'No se pudo actualizar la información. Intenta más tarde',
        {
          variant: 'error',
        },
      );
    },
  });
};

export const usePatchPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchPassword, {
    onSuccess: () => {
      enqueueSnackbar('Tu contraseña ha sido actualizada con éxito', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo actualizar la información. Intenta más tarde',
          {
            variant: 'error',
          },
        );
      }
    },
  });
};

export const useGetLinkRequests = () => {
  const dispatch = useDispatch();
  return useQuery('GET_LINK_REQUESTS', getLinkRequests, {
    onSuccess: data => {
      dispatch(setInvitations(data));
    },
  });
};

export const useAcceptLinkRequest = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(acceptLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      queryClient.invalidateQueries('GET_PATIENTS');
      enqueueSnackbar('Se vinculó con exito el paciente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'LINK_REQUEST_BY_PATIENT',
          isActive: false,
        }),
      );
    },
  });
};

export const useCancelLinkRequest = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(cancelLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      enqueueSnackbar('Se canceló la solicitud con exito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'LINK_REQUEST_BY_PATIENT',
          isActive: false,
        }),
      );
    },
  });
};

export const useRejectLinkRequest = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(rejectLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      enqueueSnackbar('Se rechazó la solicitud con exito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'LINK_REQUEST_BY_NUTRITIONIST',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE EL HISTORIAL DE COMPRAS ----
export const useGetPaymentsHistory = () => {
  const dispatch = useDispatch();
  return useQuery('GET_PAYMENTS_HISTORY', getPaymentsHistory, {
    onSuccess: data => {
      dispatch(setPaymentsHistory(data));
    },
  });
};

// ---- TRAE EL HISTORIAL DE ASIGNACIONES DE PASES ----
export const useGetInbodyPassesHistory = () => {
  const dispatch = useDispatch();
  return useQuery('GET_INBODY_PASSES_HISTORY', getInbodyPassesHistory, {
    onSuccess: data => {
      dispatch(setInbodyPassesHistory(data));
    },
  });
};

// ---- BORRA LOS BADGES DE LA TARJETA DEL PACIENTE ----
export const useDeletePatientBadges = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(deletePatientBadges, {
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- TRAE TODOS LOS EVENTOS DISPONIBLES PARA LA AGENDA VIRTUAL ----
export const useGetCalendarEvents = () => {
  const dispatch = useDispatch();

  return useQuery('GET_CALENDAR_EVENTS', getCalendarEvents, {
    onSuccess: data => {
      const events = data.map(event => {
        const { startDate, endDate, ...rest } = event;
        return {
          ...rest,
          start: startDate,
          end: endDate,
        };
      });
      dispatch(setCalendarEvents(events));
    },
  });
};

// ---- CREA UN NUEVO EVENTO PARA LA AGENDA VIRTUAL -----
export const useCreateCalendarEvent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createCalendarEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
      );
      setTimeout(() => {
        queryClient.invalidateQueries(
          '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
        );
        queryClient.invalidateQueries('GET_APPOINTMENT_COUNTERS');
      }, 1000);
      enqueueSnackbar('Se creó correctamente el evento', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('No se pudo crear el evento, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      batch(() => {
        dispatch(
          setModalStatus({
            name: 'CALENDAR_EVENT',
            isActive: false,
          }),
        );
        dispatch(
          setPendingAppointmentAfterCreatePatient({
            mode: 'normal',
            data: {},
          }),
        );
      });
    },
  });
};

// ---- ACTUALIZA UN EVENTO DE LA AGENDA VIRTUAL ----
export const useUpdateCalendarEvent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(updateCalendarEvent, {
    onSuccess: () => {
      enqueueSnackbar('Se actualizó correctamente el evento', {
        variant: 'success',
      });
      queryClient.invalidateQueries(
        '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
      );
      queryClient.invalidateQueries('GET_APPOINTMENT_COUNTERS');
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('No se pudo actualizar el evento, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CALENDAR_EVENT',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ACEPTA UN EVENTO DE LA AGENDA VIRTUAL ----
export const useAcceptCalendarEvent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(acceptCalendarEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
      );
      queryClient.invalidateQueries('GET_APPOINTMENT_COUNTERS');
      enqueueSnackbar('Se confirmó correctamente la cita con tu paciente.', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('No se pudo aceptar el evento, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CALENDAR_EVENT',
          isActive: false,
        }),
      );
    },
  });
};

// ---- RECHAZA UN EVENTO DE LA AGENDA VIRTUAL ----
export const useRejectCalendarEvent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(rejectCalendarEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
      );
      queryClient.invalidateQueries('GET_APPOINTMENT_COUNTERS');
      enqueueSnackbar('Se rechazó correctamente el evento', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('No se pudo rechazar el evento, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'REJECT_CALENDAR_EVENT_WARNING',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ELIMINA UN EVENTO DE LA AGENDA VIRTUAL ----
export const useDeleteCalendarEvent = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(deleteCalendarEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
      );
      queryClient.invalidateQueries('GET_APPOINTMENT_COUNTERS');
      enqueueSnackbar('Se eliminó correctamente el evento', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('No se pudo eliminar el evento, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CALENDAR_EVENT',
          isActive: false,
        }),
      );
    },
  });
};

// ---- PROPONE UNA NUEVA CITA PARA LA AGENDA VIRTUAL ----
export const useProposeNewDateForAppointment = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(proposeNewDateForAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        '[VIRTUAL_AGENDA] GET_NUTRITIONIST_APPOINTMENTS',
      );
      queryClient.invalidateQueries('GET_APPOINTMENT_COUNTERS');
      enqueueSnackbar('Se envío correctamente la nueva propuesta de cita', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'PROPOSE_NEW_DATE_FOR_CALENDAR_EVENT',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE LA LISTA DE TIPOS DE NUTRIOLOGOS ----
export const useGetNutritionistTypesList = () => {
  const dispatch = useDispatch();

  return useQuery('GET_NUTRITIONIST_TYPES_LIST', getNutritionistTypesList, {
    onSuccess: data => {
      dispatch(setNutritionistTypes(data));
    },
  });
};

// ---- TRAE LA LISTA DE TIPOS DE DIETAS ----
export const useGetNutritionistDietsList = () => {
  const dispatch = useDispatch();
  return useQuery('GET_NUTRITIONIST_DIETS_LIST', getNutritionistDietsList, {
    onSuccess: data => {
      dispatch(setNutritionistDiets(data));
    },
  });
};

// ---- TRAE LA LISTA DE CONSULTORIOS ----
export const useGetNutritionistOffices = () => {
  const dispatch = useDispatch();

  return useQuery('GET_NUTRITIONIST_OFFICES', getNutritionistOffices, {
    onSuccess: data => {
      dispatch(setNutritionistOffices(data));
    },
  });
};

// ---- CREA UN NUEVO CONSULTORIO PARA NUTRIÓLOGOS ----
export const useCreateNutritionistOffice = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(postCreateNutritionistOffice, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES');
      enqueueSnackbar('Se creó correctamente el consultorio', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde.', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- ACTUALIZA LA INFORMACIÓN DE UN CONSULTORIO ----
export const useUpdateNutritionistOffice = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(patchUpdateNutritionistOffice, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES');
      enqueueSnackbar(
        'Se actualizó correctamente la información del consultorio',
        {
          variant: 'success',
        },
      );
      dispatch(setEditableTarget({}));
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ----- ELIMINA UN CONSULTORIO -----
export const useDeleteNutritionistOffice = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(deleteDeleteNutritionistOffice, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES');
      enqueueSnackbar('Se eliminó correctamente el consultorio', {
        variant: 'success',
      });
      batch(() => {
        dispatch(setIsCreateOfficeFormOpen(false));
        dispatch(setEditableTarget({}));
      });
    },
    onError: ({ response }) => {
      if (response?.data.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      batch(() => {
        dispatch(
          setModalStatus({
            name: 'AFFECTED_PATIENTS_WARNING',
            isActive: false,
          }),
        );
        dispatch(
          setModalStatus({
            name: 'DELETE_NUTRITIONIST_OFFICE',
            isActive: false,
          }),
        );
      });
    },
  });
};

// ---- ACTUALIZA LA CONFIGURACION DE LOS CONSULTORIOS ----
export const useUpdateNutritionistOfficeSettings = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchUpdateNutritionistOfficeSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES');
      queryClient.invalidateQueries('GET_DETAILS');
      enqueueSnackbar('Se actualizó correctamente la información', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- TRAE LA LISTA DE LOS HORARIOS DE APERTURA DE LOS CONSULTORIOS ----
export const useGetNutritionistOfficesSchedule = () => {
  const dispatch = useDispatch();
  return useQuery(
    'GET_NUTRITIONIST_OFFICES_SCHEDULE',
    getNutritionistOfficeSchedule,
    {
      onSuccess: data => {
        dispatch(setNutritionistOfficesSchedule(data));
      },
    },
  );
};

// ---- CREA UN NUEVO HORARIO DE APERTURA PARA CONSULTORIOS ----
export const useCreateNutritionistOfficeSchedule = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(postCreateNutritionistOfficeSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES_SCHEDULE');
      enqueueSnackbar('Se registró con exito la configuración de horarios', {
        variant: 'success',
      });
      dispatch(
        setWnUserEditableOfficeHours({
          mode: '',
          data: {},
        }),
      );
    },
    onError: ({ response }) => {
      if (response?.data.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- ACTUALIZA UN HORARIO DE UN CONSULTORIO ----
export const useUpdateNutritionistOfficeSchedule = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchUpdateNutritionistOfficeSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES_SCHEDULE');
      enqueueSnackbar(
        'Se actualizó correctamente los horarios de tu consultorio',
        {
          variant: 'success',
        },
      );
      batch(() => {
        dispatch(
          setModalStatus({
            name: 'EDIT_NUTRITIONIST_OFFICE_HOURS',
            isActive: false,
          }),
        );
        dispatch(
          setWnUserEditableOfficeHours({
            mode: '',
            data: {},
          }),
        );
      });
    },
    onError: ({ response }) => {
      if (response?.data.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- BORRA UN HORARIO DE UN CONSULTORIO ----
export const useDeleteNutritionistOfficeSchedule = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(deleteDeleteNutritionistOfficeSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES_SCHEDULE');
      enqueueSnackbar(
        'Se eliminó correctamente el horario de ese consultorio',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response?.data.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONIST_OFFICE_SCHEDULE',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE HORARIOS SIN SERVICIO DE CONSULTORIOS ----
export const useGetNutritionistOfficeDaysOff = () => {
  const dispatch = useDispatch();

  return useQuery(
    'GET_NUTRITIONIST_OFFICES_DAYS_OFF',
    getNutritionistOfficeDaysOff,
    {
      onSuccess: data => {
        dispatch(setNutritionistOfficeDaysOff(data));
      },
    },
  );
};

// ---- CREA UN NUEVO HORARIO SIN SERVICIO PARA CONSULTORIO ----
export const useCreateNutritionistOfficeDayOff = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(postCreateNutritionistOfficeDayOff, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES_DAYS_OFF');
      enqueueSnackbar('Se creó correctamente el horario sin servicio', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- EDITA UN HORARIO SIN SERVICIO PARA CONSULTORIO ----
export const useEditNutritionistOfficeDayOff = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchEditNutritionistOfficeDayOff, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES_DAYS_OFF');
      enqueueSnackbar('Se actualizó correctamente el horario sin servicio', {
        variant: 'success',
      });
      dispatch(
        setModalStatus({
          name: 'EDIT_NUTRITIONIST_DAYS_OFF',
          isActive: false,
        }),
      );
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- BORRA UN HORARIO SIN SERVICIO PARA CONSULTORIO ----
export const useDeleteNutritionistOfficeDayOff = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(deleteDelteNutritionistOfficeDayOff, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONIST_OFFICES_DAYS_OFF');
      enqueueSnackbar('Se eliminó correctamente el horario sin servicio', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_NUTRITIONIST_OFFICE_DAY_OFF',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE LA LISTA DE PACIENTES AFECTADOS AL ELIMINAR UN HORARIO DE CONSULTA ----
export const useGetOfficeHoursAffectedPatients = id => {
  const dispatch = useDispatch();
  return useQuery(
    'GET_OFFICE_HOURS_AFFECTED_PATIENTS',
    () => getOfficeHoursAffectedPatients(id),
    {
      enabled: false,
      onSuccess: (data = []) => {
        const accepted = data.filter(item => item.status === 'accepted');
        dispatch(setAffectedPatients(accepted));
      },
    },
  );
};

// ---- TRAE LA LISTA DE PACIENTES AFECTADOS AL ELIMINAR UN CONSULTORIO ----
export const useGetDeleteOfficeAffectedPatients = id => {
  const dispatch = useDispatch();

  return useQuery(
    'GET_AFFECTED_PATIENTS',
    () => getDeleteOffieAffectedPatients(id),
    {
      enabled: false,
      onSuccess: data => {
        dispatch(setAffectedPatients(data));
      },
    },
  );
};

// ---- TRAE LA CANTIDAD DE CONTADORES PARA CITAS EN AGENDA VIRTUAL ----
export const useGetAppointmentCounters = () => {
  return useQuery('GET_APPOINTMENT_COUNTERS', getAppointmentCounters);
};

// ---- TRAE LA LISTA DE USUARIOS CON CITAS PENDIENTES POR CONFIRMAR ----
export const useGetPendingAppointmentsUserList = () => {
  const dispatch = useDispatch();
  return useQuery(
    'GET_PENDING_APPOINTMENTS_USER_LIST',
    getPendingAppointmentsUserList,
    {
      onSuccess: data => {
        dispatch(setUnseenAppointments(data));
      },
    },
  );
};

// ---- TRAE LOS INDICADORES DE NUEVAS NOTIFICACIONES ----
export const useGetNotificationCounter = () => {
  const dispatch = useDispatch();

  return useQuery(
    'GET_NEW_NUTRITIONIST_NOTIFICATIONS',
    getNewNutritionistNotifications,
    {
      onSuccess: data => {
        dispatch(setWnNotificationCounter(data.new));
      },
    },
  );
};

// ---- TRAE LAS NOTIFICACIONES PENDIENDES ----
export const useGetNotifications = () => {
  const dispatch = useDispatch();
  return useQuery('GET_NOTIFICATIONS', getNotifications, {
    enabled: false,
    onSuccess: data => {
      const notifications = data.map(item => ({
        ...item,
        actionable: [
          NOTIFICATION_TYPES.REQUESTS.LINK,
          NOTIFICATION_TYPES.INBODY.NEW,
          NOTIFICATION_TYPES.APPOINTMENT.RESCHEDULE,
        ].includes(item.type),
      }));
      batch(() => {
        dispatch(setWnNotificationCounter(0));
        dispatch(setWnNotifications(notifications));
      });
    },
  });
};

// ---- MARCA COMO VISTA UNA NOTIFICACIÓN ----
export const useMarkAsSeenNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(markAsSeenNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NOTIFICATIONS');
    },
  });
};
