import { useSelector } from 'react-redux';

import WithModal from '../../HOC/WithModal/WithModal.component';

import ResetPasswordForm from '../../Forms/ResetPassword/ResetPasswordForm.component';
import PatientCreationForm from '../../Forms/Patient/Creation/PatientCreationForm.component';
import PatientPassesForm from '../../Forms/Patient/Passes/PatientPassesForm.component';
import PatientVerificationForm from '../../Forms/Patient/Verification/PatientVerificationForm.component';
import SetDefaultCardForm from '../../Forms/SetDefaultCard/SetDefaultCardForm.component';
import DeleteCardForm from '../../Forms/DeleteCard/DeleteCardForm.component';
import AddServiceForm from '../../Forms/AddService/AddServiceForm.component';

import WellcomeInfo from '../../Infos/Wellcome/WellcomeInfo.component';
import UnsubscribeForm from '../../Forms/Unsubscribe/UnsubscribeForm.component';
import DeleteNutritionalPlanForm from '../../Forms/DeleteNutritionalPlan/DeleteNutritionalPlanForm.component';
import DeleteBiochemicalPlanForm from '../../Forms/DeleteBiochemicalPlan/DeleteBiochemicalPlanForm.component';
import AddPatientConfirmationForm from '../../Forms/AddPatientConfirmation/AddPatientConfirmationForm.component';
import ActivatePatientConfirmation from '../../Forms/ActivatePatientConfirmation/ActivatePatientConfirmation.component';
import InactivatePatientConfirmation from '../../Forms/InactivatePatientConfirmation/InactivatePatientConfirmation.component';
import LinkRequestByNutritionistForm from 'components/Forms/LinkRequestByNutritionist/LinkRequestByNutritionist.component';
import CancelLinkRequest from 'components/Forms/CancelLinkRequest/CancelLinkRequest.component';
import RejectLinkRequest from 'components/Forms/RejectLinkRequest/RejectLinkRequest.component';
import LinkRequestByPatient from 'components/Forms/LinkRequestByPatient/LinkRequestByPatient.component';
import AcceptLinkRequest from 'components/Forms/AcceptLinkRequest/AcceptLinkRequest.component';

import CalendarEventPanel from '../Panels/CalendarEvent/CalendarEventPanel.component';
import CompleteProfileWarningPanel from '../Panels/CompleteProfileWarning/CompleteProfileWarningPanel.component';
import CompleteServicesWarningPanel from '../Panels/CompleteServicesWarningPanel/CompleteServicesWarningPanel.component';
import MissingNutritionistProfilePanel from '../Panels/MissingNutritionistProfile/MissingNutritionistProfilePanel.component';
import DeleteNutritionistServicePanel from '../Panels/DeleteNutritionistService/DeleteNutritionistServicePanel.component';
import DeleteNutritionistOfficePanel from '../Panels/DeleteNutritionistOffice/DeleteNutritionistOfficePanel.component';
import DeleteNutritionistOfficeSchedulePanel from '../Panels/DeleteNutritionistOfficeSchedule/DeleteNutritionistOfficeSchedulePanel.component';
import DeleteNutritionistOfficeDayOffPanel from '../Panels/DeleteNutritionistOfficeDayOff/DeleteNutritionistOfficeDayOffPanel.component';
import DeleteOfficeWarningPanel from '../Panels/DeleteOfficeWarning/DeleteOfficeWarningPanel.component';
import CompleteVirtualAgendaSettingsWarningPanel from '../Panels/CompleteVirtualAgendaSettingsWarning/CompleteVirtualAgendaSettingsWarningPanel.component';
import RejectCalendarEventWarningPanel from '../Panels/RejectCalendarEventWarning/RejectCalendarEventWarningPanel.component';
import ProposeNewDateForCalendarEventPanel from '../Panels/ProposeNewDateForCalendarEvent/ProposeNewDateForCalendarEventPanel.component';
import InactivePatientWithAppointmentWarningPanel from '../Panels/InactivePatientWithAppointmentWarningPanel/InactivePatientWithAppointmentWarningPanel.component';
import UnseenAppointmentsWarningPanel from '../Panels/UnseenAppointments/UnseenAppointmentsWarningPanel.component';
import NoServicesWarningPanelComponent from '../Panels/NoServicesWarningPanel/NoServicesWarningPanel.component';
import NoOfficesWarningPanel from '../Panels/NoOfficesWarningPanel/NoOfficesWarningPanel.component';
import EditNutritionstOfficeHoursPanel from '../Panels/EditNutritionstOfficeHoursPanel/EditNutritionstOfficeHoursPanel.component';
import EmptyOfficesWarningPanel from '../Panels/EmptyOfficesWarningPanel/EmptyOfficesWarningPanel.component';
import EditCalendarDaysOffPanel from '../Panels/EditCalendarDaysOff/EditCalendarDaysOffPanel.component';
import CompletedProfileWarningPanel from '../Panels/CompletedProfileWaning/CompletedProfileWarningPanel.component';
import AvoidToDeleteServiceWarning from '../Panels/AvoidToDeleteServiceWarning/AvoidToDeleteServiceWarning.component';
import AvoidToDeleteOfficeWarning from '../Panels/AvoidToDeleteOfficeWarning/AvoidToDeleteOfficeWarning.component';
import VirtualAgendaRestrictionWarningPanel from '../Panels/VirtualAgendaRestrictionWarning/VirtualAgendaRestrictionWarningPanel.component';
import UpgradeToBasicSubscriptionModal from '../Panels/VirtualAgendaRestrictionWarning/UpgradeToBasicSubscriptionModal.component';
import AvoidToDeleteOfficeHours from "../Panels/AvoidToDeleteOfficeHours/AvoidToDeleteOfficeHours.component";
import PendingAgendaSettingsPanel from "../Panels/PendingAgendaSettings/PendingAgendaSettingsPanel.component";

const ModalManager = () => {
  const { name, isActive } = useSelector(state => state.modal);

  const { event } = useSelector(state => state.calendarEvents);

  switch (name) {
    case 'PASSWORD_RESET':
      return (
        <WithModal
          open={isActive}
          name="PASSWORD_RESET"
          title="Recuperar contraseña"
          BodyComponent={<ResetPasswordForm />}
        />
      );
    case 'PATIENT_VERIFICATION':
      return (
        <WithModal
          open={isActive}
          name="PATIENT_VERIFICATION"
          title="Agregar paciente"
          BodyComponent={<PatientVerificationForm />}
        />
      );
    case 'PATIENT_CREATION':
      return (
        <WithModal
          open={isActive}
          name="PATIENT_CREATION"
          title="Agregar paciente"
          BodyComponent={<PatientCreationForm />}
        />
      );
    case 'ADD_INBODY_PASSES':
      return (
        <WithModal
          open={isActive}
          name="ADD_INBODY_PASSES"
          title="Agregar Pases"
          BodyComponent={<PatientPassesForm />}
        />
      );
    case 'SET_DEFAULT_CARD':
      return (
        <WithModal
          open={isActive}
          name="SET_DEFAULT_CARD"
          title="Cambiar tarjeta principal"
          BodyComponent={<SetDefaultCardForm />}
        />
      );
    case 'DELETE_CARD':
      return (
        <WithModal
          open={isActive}
          name="DELETE_CARD"
          title="Eliminar forma de pago"
          BodyComponent={<DeleteCardForm />}
        />
      );
    case 'ADD_SERVICE':
      return (
        <WithModal
          open={isActive}
          name="ADD_SERVICE"
          title="Agregar nuevo servicio"
          BodyComponent={<AddServiceForm />}
        />
      );
    case 'SIGNUP_WELLCOME':
      return (
        <WithModal
          open={isActive}
          name="SIGNUP_WELLCOME"
          title="Bienvenido(a)"
          BodyComponent={<WellcomeInfo />}
        />
      );
    case 'UNSUBSCRIBE':
      return (
        <WithModal
          open={isActive}
          name="UNSUBSCRIBE"
          title="Cancelar suscripción"
          BodyComponent={<UnsubscribeForm />}
        />
      );
    case 'DELETE_NUTRITIONAL_PLAN':
      return (
        <WithModal
          open={isActive}
          name="DELETE_NUTRITIONAL_PLAN"
          title="Eliminar plan de nutrición"
          BodyComponent={<DeleteNutritionalPlanForm />}
        />
      );
    case 'DELETE_BIOCHEMICAL_PLAN':
      return (
        <WithModal
          open={isActive}
          name="DELETE_BIOCHEMICAL_PLAN"
          title="Eliminar análisis clínico"
          BodyComponent={<DeleteBiochemicalPlanForm />}
        />
      );
    case 'ADD_PATIENT_CONFIRMATION':
      return (
        <WithModal
          open={isActive}
          name="ADD_PATIENT_CONFIRMATION"
          title="Confirmación nuevo usuario Wellnub"
          BodyComponent={<AddPatientConfirmationForm />}
        />
      );
    case 'ACTIVATE_PATIENT_CONFIRMATION':
      return (
        <WithModal
          open={isActive}
          name="ACTIVATE_PATIENT_CONFIRMATION"
          title="Activar paciente"
          BodyComponent={<ActivatePatientConfirmation />}
        />
      );
    case 'INACTIVATE_PATIENT_CONFIRMATION':
      return (
        <WithModal
          open={isActive}
          name="INACTIVATE_PATIENT_CONFIRMATION"
          title="Desactivar paciente"
          BodyComponent={<InactivatePatientConfirmation />}
        />
      );
    case 'LINK_REQUEST_BY_PATIENT':
      return (
        <WithModal
          open={isActive}
          name="LINK_REQUEST_BY_PATIENT"
          title="Solicitud pendiente"
          BodyComponent={<LinkRequestByPatient />}
        />
      );
    case 'LINK_REQUEST_BY_NUTRITIONIST':
      return (
        <WithModal
          open={isActive}
          name="LINK_REQUEST_BY_NUTRITIONIST"
          title="Solicitud pendiente"
          BodyComponent={<LinkRequestByNutritionistForm />}
        />
      );
    case 'CANCEL_LINK_REQUEST':
      return (
        <WithModal
          open={isActive}
          name="CANCEL_LINK_REQUEST"
          title="Solicitud pendiente"
          BodyComponent={<CancelLinkRequest />}
        />
      );
    case 'REJECT_LINK_REQUEST':
      return (
        <WithModal
          open={isActive}
          name="REJECT_LINK_REQUEST"
          title="Solicitud pendiente"
          BodyComponent={<RejectLinkRequest />}
        />
      );
    case 'ACCEPT_LINK_REQUEST':
      return (
        <WithModal
          open={isActive}
          name="ACCEPT_LINK_REQUEST"
          title="Solicitud pendiente"
          BodyComponent={<AcceptLinkRequest />}
        />
      );
    case 'CALENDAR_EVENT':
      return (
        <WithModal
          open={isActive}
          name="CALENDAR_EVENT"
          title={event.title}
          BodyComponent={<CalendarEventPanel />}
        />
      );
    case 'PROFILE_WARNING':
      return (
        <WithModal
          open={isActive}
          name="PROFILE_WARNING"
          title="Completa tu perfil"
          BodyComponent={<CompleteProfileWarningPanel />}
        />
      );
    case 'PROFILE_SERVICES':
      return (
        <WithModal
          open={isActive}
          name="PROFILE_SERVICE"
          title="Agrega servicios a tu perfil"
          BodyComponent={<CompleteServicesWarningPanel />}
        />
      );
    case 'MISSING_NUTRITIONIST_PROFILE':
      return (
        <WithModal
          open={isActive}
          name="MISSING_NUTRITIONIST_PROFILE"
          title="Aviso importante"
          BodyComponent={<MissingNutritionistProfilePanel />}
        />
      );
    case 'DELETE_NUTRITIONIST_SERVICE':
      return (
        <WithModal
          open={isActive}
          name="DELETE_NUTRITIONIST_SERVICE"
          title="Eliminar servicio"
          BodyComponent={<DeleteNutritionistServicePanel />}
        />
      );
    case 'DELETE_NUTRITIONIST_OFFICE':
      return (
        <WithModal
          open={isActive}
          name="DELETE_NUTRITIONIST_OFFICE"
          title="Eliminar consultorio"
          BodyComponent={<DeleteNutritionistOfficePanel />}
        />
      );
    case 'DELETE_NUTRITIONIST_OFFICE_SCHEDULE':
      return (
        <WithModal
          open={isActive}
          name="DELETE_NUTRITIONIST_OFFICE_SCHEDULE"
          title="Eliminar horario"
          BodyComponent={<DeleteNutritionistOfficeSchedulePanel />}
        />
      );
    case 'DELETE_NUTRITIONIST_OFFICE_DAY_OFF':
      return (
        <WithModal
          open={isActive}
          name="DELETE_NUTRITIONIST_OFFICE_DAY_OFF"
          title="Eliminar horario"
          BodyComponent={<DeleteNutritionistOfficeDayOffPanel />}
        />
      );
    case 'AFFECTED_PATIENTS_WARNING':
      return (
        <WithModal
          open={isActive}
          name="AFFECTED_PATIENTS_WARNING"
          title="Editar consultorio"
          BodyComponent={<DeleteOfficeWarningPanel />}
        />
      );
    case 'COMPLETE_VIRTUAL_AGENDA_SETTINGS_WARNING':
      return (
        <WithModal
          open={isActive}
          name="COMPLETE_VIRTUAL_AGENDA_SETTINGS_WARNING"
          title="Aviso Importante"
          BodyComponent={<CompleteVirtualAgendaSettingsWarningPanel />}
        />
      );
    case 'REJECT_CALENDAR_EVENT_WARNING':
      return (
        <WithModal
          open={isActive}
          name="REJECT_CALENDAR_EVENT_WARNING"
          title="Rechazar cita"
          BodyComponent={<RejectCalendarEventWarningPanel />}
        />
      );
    case 'PROPOSE_NEW_DATE_FOR_CALENDAR_EVENT':
      return (
        <WithModal
          open={isActive}
          name="PROPOSE_NEW_DATE_FOR_CALENDAR_EVENT"
          title="Editar Evento"
          BodyComponent={<ProposeNewDateForCalendarEventPanel />}
        />
      );
    case 'INACTIVE_PATIENT_WITH_APPOINTMENT_WARNING':
      return (
        <WithModal
          open={isActive}
          name="INACTIVE_PATIENT_WITH_APPOINTMENT_WARNING"
          title="Aviso Importante"
          BodyComponent={<InactivePatientWithAppointmentWarningPanel />}
        />
      );
    case 'UNSEEN_REJECTED_AND_CANCELLED_APPOINTMENTS_ALERT':
      return (
        <WithModal
          open={isActive}
          name="UNSEEN_REJECTED_AND_CANCELLED_APPOINTMENTS_ALERT"
          title="Aviso Importante"
          BodyComponent={<UnseenAppointmentsWarningPanel />}
        />
      );
    case 'NO_SERVICES_WARNING':
      return (
        <WithModal
          open={isActive}
          name="NO_SERVICES_WARNING"
          title="Aviso Importante"
          BodyComponent={<NoServicesWarningPanelComponent />}
        />
      );
    case 'NO_OFFICES_WARNING':
      return (
        <WithModal
          open={isActive}
          name="NO_OFFICES_WARNING"
          title="Aviso Importante"
          BodyComponent={<NoOfficesWarningPanel />}
        />
      );
    case 'EDIT_NUTRITIONIST_OFFICE_HOURS':
      return (
        <WithModal
          open={isActive}
          name="EDIT_NUTRITIONIST_OFFICE_HOURS"
          title="Editar Horario de Consulta"
          BodyComponent={<EditNutritionstOfficeHoursPanel />}
        />
      );
    case 'EMPTY_OFFICES_WARNING':
      return (
        <WithModal
          open={isActive}
          name="EMPTY_OFFICES_WARNING"
          title="Agrega consultorios a tu perfil"
          BodyComponent={<EmptyOfficesWarningPanel />}
        />
      );
    case 'EDIT_NUTRITIONIST_DAYS_OFF':
      return (
        <WithModal
          open={isActive}
          name="EDIT_NUTRITIONIST_DAYS_OFF"
          title="Editar días sin servicio"
          BodyComponent={<EditCalendarDaysOffPanel />}
        />
      );
    case 'COMPLETED_PROFILE_WARNING':
      return (
        <WithModal
          open={isActive}
          name="COMPLETED_PROFILE_WARNING"
          title="Has completado tu perfil"
          BodyComponent={<CompletedProfileWarningPanel />}
        />
      );
    case 'AVOID_TO_DELETE_SERVICES_WARNING':
      return (
        <WithModal
          open={isActive}
          name="AVOID_TO_DELETE_SERVICES_WARNING"
          title="Eliminar servicio"
          BodyComponent={<AvoidToDeleteServiceWarning />}
        />
      );
    case 'AVOID_TO_DELETE_OFFICE_WARNING':
      return (
        <WithModal
          open={isActive}
          name="AVOID_TO_DELETE_OFFICE_WARNING"
          title="Eliminar consultorio"
          BodyComponent={<AvoidToDeleteOfficeWarning />}
        />
      );
    case 'VIRTUAL_AGENDA_RESTRICTION_WARNING':
      return (
        <WithModal
          open={isActive}
          name="VIRTUAL_AGENDA_RESTRICTION_WARNING"
          title="Advertencia"
          BodyComponent={<VirtualAgendaRestrictionWarningPanel />}
        />
      );
    case 'UPGRADE_TO_BASIC_SUBSCRIPTION':
      return (
        <WithModal
          open={isActive}
          name="UPGRADE_TO_BASIC_SUBSCRIPTION"
          title="Aviso Importante"
          BodyComponent={<UpgradeToBasicSubscriptionModal />}
        />
      );
    case 'AVOID_TO_DELETE_OFFICE_HOURS_WARNING':
      return (
        <WithModal
          open={isActive}
          name="AVOID_TO_DELETE_OFFICE_HOURS_WARNING"
          title="Eliminar Horario de Consulta"
          BodyComponent={<AvoidToDeleteOfficeHours />}
        />
      );
    case 'PENDING_AGENDA_SETTINGS':
      return (
        <WithModal
          open={isActive}
          name="PENDING_AGENDA_SETTINGS"
          title="Configura tu Agenda Virtual"
          BodyComponent={<PendingAgendaSettingsPanel />}
        />
      );
    default:
      return null;
  }
};

export default ModalManager;
