import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider, Slide, CssBaseline } from '@material-ui/core';

import App from './App';

import { store } from 'redux/store';

import { theme } from 'styles/theme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, { response: { data } }) => {
        return data.code === 401 && data.message === 'jwt expired';
      },
      refetchOnWindowFocus: false,
    },
  },
});

window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <SnackbarProvider
            preventDuplicate
            maxSnack={4}
            TransitionComponent={Slide}
          >
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY}
            >
              <App />
            </GoogleOAuthProvider>
            <CssBaseline />
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
