import { createSlice } from '@reduxjs/toolkit';

const tokenResponseSlice = createSlice({
  name: 'tokenResponse',
  initialState: null,
  reducers: {
    setTokenResponse: (state, action) => {
      state.tokenResponse = action.payload;
    },
  },
});

export const { setTokenResponse } = tokenResponseSlice.actions;
export const TokenResponseReducer = tokenResponseSlice.reducer;
