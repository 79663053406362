import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { useStyles } from './SettingsAgendaNavigation.styles';

const SettingsAgendaNavigation = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const classes = useStyles();

  const getTabValue = () => {
    if (pathname.includes('availability')) return 'availability';
    if (pathname.includes('office-hours')) return 'office-hours';
    if (pathname.includes('days-off')) return 'days-off';
    if (pathname.includes('app-sync')) return 'app-sync';
    return 'availability';
  };

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={getTabValue()}
      className={classes.tabs}
      indicatorColor="primary"
    >
      <Tab
        to={`${url}/availability`}
        component={Link}
        value="availability"
        label="Disponibilidad"
        classes={{ wrapper: classes.tab }}
      />
      <Tab
        to={`${url}/office-hours`}
        component={Link}
        value="office-hours"
        label="Horarios de consulta"
        classes={{ wrapper: classes.tab }}
      />
      <Tab
        to={`${url}/days-off`}
        component={Link}
        value="days-off"
        label="Días sin servicio"
        classes={{ wrapper: classes.tab }}
      />
      <Tab
        to={`${url}/app-sync`}
        component={Link}
        value="days-off"
        label="Sincroniza tus apps"
        classes={{ wrapper: classes.tab }}
      />
    </Tabs>
  );
};

export default SettingsAgendaNavigation;
