import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { useHasLoggedIn } from 'hooks/authentication.hook';
import { setModalStatus } from 'redux/slices/modal.slice';

const PendingAgendaSettingsPanel = () => {
  const { mutate } = useHasLoggedIn();

  const dispatch = useDispatch();

  const { replace } = useHistory();

  const onConfigureNowHandler = () => {
    dispatch(
      setModalStatus({
        name: 'PENDING_AGENDA_SETTINGS',
        isActive: false,
      }),
    );
    setTimeout(() => {
      replace('/settings/virtual-agenda/availability');
      mutate({ hasLoggedIn: true });
    }, 500);
  };

  const onConfigureLaterHandler = () => {
    dispatch(
      setModalStatus({
        name: 'PENDING_AGENDA_SETTINGS',
        isActive: false,
      }),
    );
    setTimeout(() => {
      mutate({ hasLoggedIn: true });
      replace('/dashboard/patients');
    }, 500);
  };

  return (
    <Box textAlign="center">
      <Typography variant="body2" style={{ marginBottom: '8px' }}>
        La Agenda Virtual cuenta con una configuración de disponibilidad y
        horarios por default.
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '8px' }}>
        Sin embargo, puedes configurar tus preferencias para que puedas sacarle
        el máximo provecho.
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '8px' }}>
        Puedes hacerlo ahora o en otro momento
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfigureNowHandler}
        >
          Configurar ahora
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onConfigureLaterHandler}
        >
          Configurar después
        </Button>
      </Box>
    </Box>
  );
};

export default PendingAgendaSettingsPanel;
