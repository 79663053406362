import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useUnsubscribe } from 'hooks/payments.hook';

const UpgradeToBasicSubscriptionModal = () => {
  const { mutate: unsubscribe, isLoading: isLoadingUnsubscribe } =
    useUnsubscribe();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    unsubscribe();
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'UPGRADE_TO_BASIC_SUBSCRIPTION',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="body2" align="center" style={{ paddingBottom: '8px' }}>
        Tu suscripción Premium permanecerá vigente hasta acabar el periodo actual
      </Typography>
      <Typography variant="body2" align="center" style={{paddingBottom: '8px'}}>Una vez concluido el periodo, tu suscripción se actualizará</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoadingUnsubscribe}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default UpgradeToBasicSubscriptionModal;
