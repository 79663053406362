import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import {
  AppBar,
  Container,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import PatientsPage from '../Patients/Patients.page';
import VirtualAgendaPage from '../VirtualAgenda/VirtualAgenda.page';

import { setModalStatus } from 'redux/slices/modal.slice';
import {
  useGetAppointmentCounters,
  useGetCalendarEvents,
  useGetNutritionistOfficesSchedule,
  useNutritionist,
} from 'hooks/nutritionist.hook';
import { useGetNutritionistAppointments } from 'hooks/virtualAgenda.hook';

import { useStyles } from './Dashboard.styles';
import { virtualAgendaRestrictionSelector } from 'redux/slices/virtualAgenda/virtualAgenda.selector';
import { VIRTUAL_AGENDA_RESTRICTIONS } from '../../utils/constants';
import VirtualAgendaBlockedPage from '../VirtualAgendaBlocked/VirtualAgendaBlocked.page';
import { useGetSubscription } from '../../hooks/payments.hook';

const DashboardPage = () => {
  const { isLoading: isLoadingSchedule, isError: isErrorSchedule } =
    useGetNutritionistOfficesSchedule();

  const { isLoading: isLoadingEvents, isError: isErrorInEvents } =
    useGetCalendarEvents();

  const {
    isLoading: isLoadingNutritionistAppointments,
    isError: isErrorOnNutritionistAppointmens,
  } = useGetNutritionistAppointments();

  const { isLoading, isError } = useNutritionist();

  const virtualAgendaRestritcion = useSelector(
    virtualAgendaRestrictionSelector,
  );

  const {
    isLoading: isLoadingCounters,
    isError: isErrorCounters,
    data: counters,
  } = useGetAppointmentCounters();

  const isFisrtSignIn = useSelector(state => state.user.isFirstSignIn);

  const { url, path } = useRouteMatch();

  const { replace } = useHistory();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const classes = useStyles({
    withNotification: counters?.mine || counters?.new,
  });

  const getTabValue = () => {
    if (pathname.includes('virtual-agenda')) return 'virtual-agenda';
    if (pathname.includes('patients')) return 'patients';
    return 'progress';
  };

  // useEffect(() => {
  // if (!isEmpty(data)) {
  //   const {
  //     nutritionistProfile: { diets, types, services, specialities, settings },
  //     hasLoggedIn,
  //   } = data;
  //   const hasToCompleteProfile = isEmpty(specialities);
  //   const hasToCompleteServices = isEmpty(services);
  //   const hasToFillDietsProfile = isEmpty(diets) || isEmpty(types);
  //   const hasToCompleteAgendaSettings = Object.values(settings).some(
  //     item => item === null,
  //   );
  //   const hasToCreateOfficeSchedule = isEmpty(nutritionistSchedule);
  //   if (hasToCompleteProfile) {
  //     replace('/settings/profile/specialties');
  //     setTimeout(() => {
  //       dispatch(
  //         setModalStatus({
  //           name: 'PROFILE_WARNING',
  //           isActive: true,
  //         }),
  //       );
  //     }, 1000);
  //     return;
  //   }
  //   if (hasToCompleteServices) {
  //     replace('/settings/profile/services');
  //     setTimeout(() => {
  //       dispatch(
  //         setModalStatus({
  //           name: 'PROFILE_SERVICES',
  //           isActive: true,
  //         }),
  //       );
  //     });
  //     return;
  //   }
  //   if (!hasLoggedIn) {
  //     replace('/settings/profile/market/suscriptions');
  //     return;
  //   }
  //   if (hasToFillDietsProfile) {
  //     setTimeout(() => {
  //       dispatch(
  //         setModalStatus({
  //           name: 'MISSING_NUTRITIONIST_PROFILE',
  //           isActive: true,
  //         }),
  //       );
  //     }, 1000);
  //     // return;
  //   }
  // if (hasToCompleteAgendaSettings || hasToCreateOfficeSchedule) {
  //   setTimeout(() => {
  //     dispatch(
  //       setModalStatus({
  //         name: 'COMPLETE_VIRTUAL_AGENDA_SETTINGS_WARNING',
  //         isActive: true,
  //       }),
  //     );
  //   }, 1000);
  // }
  //   }
  // }, [data, replace, dispatch, nutritionistSchedule]);

  useEffect(() => {
    if (isFisrtSignIn) {
      replace('/settings/profile/general');
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'PROFILE_WARNING',
            isActive: true,
          }),
        );
      }, 1000);
    }
  }, [isFisrtSignIn]);

  if (
    isLoading ||
    isLoadingSchedule ||
    isLoadingCounters ||
    isLoadingEvents ||
    isLoadingNutritionistAppointments
  ) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (
    isError ||
    isErrorSchedule ||
    isErrorCounters ||
    isErrorInEvents ||
    isErrorOnNutritionistAppointmens
  ) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography>
          No se pudo cargar la información, intenta más tarde
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          classes={{ root: classes.tabs }}
          value={getTabValue()}
        >
          <Tab
            value="virtual-agenda"
            disabled={
              virtualAgendaRestritcion === VIRTUAL_AGENDA_RESTRICTIONS.HIGH
            }
            component={Link}
            to={
              virtualAgendaRestritcion === VIRTUAL_AGENDA_RESTRICTIONS.MID
                ? `${url}/virtual-agenda-blocked`
                : `${url}/virtual-agenda`
            }
            label="Mi agenda virtual - ¡Espéralo pronto!"
            classes={{ root: classes.tab, selected: classes.selected }}
            className={classes.withNotification}
          />
          <Tab
            value="patients"
            component={Link}
            to={`${url}/patients`}
            label="Mis pacientes"
            classes={{ root: classes.tab, selected: classes.selected }}
          />
        </Tabs>
      </AppBar>
      <Switch>
        <Route exact path={path}>
          <Redirect from={path} to={`${url}/patients`} />
        </Route>
        <Route
          path={`${path}/virtual-agenda-blocked`}
          component={VirtualAgendaBlockedPage}
        />
        <Route path={`${path}/virtual-agenda`} component={VirtualAgendaPage} />
        <Route path={`${path}/patients`} component={PatientsPage} />
      </Switch>
    </Container>
  );
};

export default DashboardPage;
