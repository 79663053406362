import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import {
  requestAddCard,
  requestBundles,
  requestGetCards,
  requestSuscription,
  requestPaymentApprovement,
  requestSetDefaultCard,
  requestBuyBundle,
  requestDeleteCard,
  requestUnsubscribe,
  requestGetInterestFreeList,
  requestValidateDiscountCode,
  requestGetSubscription,
} from 'network/services/payments.service';

import { addCard } from 'redux/slices/payments.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setIsSubscribed } from 'redux/slices/user.slice';
import { setInbodyBundles } from 'redux/slices/market/market.slice';

import { useDrawer } from './drawers.hook';

import { DRAWERS } from 'utils/constants';
import { setSubscription } from '../redux/slices/subscription/subscription.slice';

// ---- TRAE LOS PAQUETES DE PASES INBODY ----
export const useBundles = () => {
  const dispatch = useDispatch();

  return useQuery('GET_BUNDLES', requestBundles, {
    onSuccess: ({ data }) => {
      dispatch(setInbodyBundles(data));
    },
  });
};

export const usePayments = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestPaymentApprovement, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PASSES');
      enqueueSnackbar('Se completó la compra con éxito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la compra', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      });
    },
  });
};

export const useSuscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.checkout);
  const dispatch = useDispatch();

  return useMutation(requestSuscription, {
    onSuccess: () => {
      dispatch(setIsSubscribed(true));
      queryClient.invalidateQueries('GET_PLAN_USERS');
      enqueueSnackbar('Se completó con éxito la suscripción mensual', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- AÑADE UNA NUEVA TARJETA DE CRÉDITO ----
export const useAddCard = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.addCard);

  return useMutation(requestAddCard, {
    onSuccess: ({ data }) => {
      dispatch(addCard(data));
      queryClient.invalidateQueries('GET_CARDS');
      enqueueSnackbar('Tarjeta agregada con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo agregar la tarjeta. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- TRAE EL LISTADO DE TARJETAS DE CRÉDITO ----
export const useGetCards = () => useQuery('GET_CARDS', requestGetCards);

// ---- CAMBIA UNA TARJETA A ESTADO POR DEFECTO ----
export const useSetDefaultCard = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestSetDefaultCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CARDS');
      enqueueSnackbar('Se cambió la forma de pago principal', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'SET_DEFAULT_CARD',
          isActive: false,
        }),
      );
    },
  });
};

// ---- REALIZA LA COMPRA DE PAQUETES DE PASES ----
export const useBuyBundle = () => {
  const queryClient = useQueryClient();
  const { handleClose } = useDrawer(DRAWERS.checkout);

  return useMutation(requestBuyBundle, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PASSES');
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- ELIMINA UNA TARJETA ----
export const useDeleteCard = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestDeleteCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CARDS');
      enqueueSnackbar('Se eliminó correctamente la tarjeta', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_CARD',
          isActive: false,
        }),
      );
    },
  });
};

// ---- CANCELA UNA SUSCRIPCIÓN ----
export const useUnsubscribe = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(requestUnsubscribe, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PLAN_USERS');
      enqueueSnackbar('Tu suscripción se ha actualizado con éxito', {
        variant: 'success',
      });
      dispatch(
        setModalStatus({
          name: 'UPGRADE_TO_BASIC_SUBSCRIPTION',
          isActive: false,
        }),
      );
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- TRAE LA LISTA DE PAQUETES INBODY CON MESES SIN INTERESES ----
export const useGetInterestFreeList = () => {
  return useQuery('GET_INTEREST_FREE_LIST', requestGetInterestFreeList);
};

// ---- VALIDA UN CÓDIGO PROMOCIONAL PARA APLICAR UN DESCUENTO ----
export const useValidateDiscountCode = discountCode => {
  return useQuery(
    'VALIDATE_DISCOUNT_CODE',
    () => requestValidateDiscountCode(discountCode),
    {
      enabled: false,
    },
  );
};

// ---- VERIFICA EL ESTADO ACTUAL DE LA SUSCRIPCIÓN ----
export const useGetSubscription = () => {
  const dispatch = useDispatch();

  return useQuery('GET_SUBSCRIPTION', requestGetSubscription, {
    onSuccess: response => {
      dispatch(setSubscription(response));
    },
  });
};
