import { wellnub } from '../wellnub.api';

export const requestBundles = () =>
  wellnub.get('/v1/nutritionist/storeProduct/coin');

export const requestGetSubscription = async () => {
  const { data } = await wellnub.get('/v1/nutritionist/suscription/suscribe');
  return data;
};

export const requestSuscription = data =>
  wellnub.post('/v1/nutritionist/suscription/suscribe', data);

export const requestPaymentApprovement = data =>
  wellnub.post('/v1/partner/payment/buyCoins', data);

export const requestBuyBundle = payload =>
  wellnub.post('/v1/nutritionist/payment/buyCoins', payload);

export const requestAddCard = payload =>
  wellnub.post('/v1/nutritionist/payment/card', payload);

export const requestGetCards = () =>
  wellnub.get('/v1/nutritionist/payment/card');

export const requestSetDefaultCard = id =>
  wellnub.patch(`/v1/nutritionist/suscription/suscribe`, {
    cardId: id,
  });

export const requestDeleteCard = id =>
  wellnub.delete(`/v1/nutritionist/payment/card/${id}`);

export const requestUnsubscribe = () =>
  wellnub.delete('/v1/nutritionist/suscription/suscribe');

export const requestGetInterestFreeList = async () => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/payment/instalment/search?section=buyPasses`,
  );
  return data;
};

export const requestValidateDiscountCode = async discountCode => {
  const { data } = await wellnub.get(
    `/v1/nutritionist/payment/promotion/search?code=${discountCode}`,
  );
  return data;
};
