import {Box, Button, Typography} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {setModalStatus} from "redux/slices/modal.slice";

const AvoidToDeleteOfficeHours = () => {
	const dispatch = useDispatch();

	const onAcceptHandler = () => {
		dispatch(setModalStatus({
			name: 'AVOID_TO_DELETE_OFFICE_HOURS_WARNING',
			isActive: false,
		}))
	}

	return (
			<Box textAlign="center">
				<Typography variant="h6" align="center">¡Recuerda!</Typography>
				<Typography variant="body2" style={{marginBottom: 16}}>Para la correcta funcionalidad de la Agenda Virtual, debes contar con al menos un horario configurado.</Typography>
				<Button variant="contained" color="primary" onClick={onAcceptHandler}>Aceptar</Button>
			</Box>
	)
}

export default AvoidToDeleteOfficeHours